<template>
  <div class="flex flex-col items-center w-full h-full">
    <div class="flex flex-col w-full h-full overflow-auto">
      <div
        class="flex flex-col justify-center flex-shrink-0 w-full p-2 my-1 text-gray-800 bg-white shadow"
      >
        <div class="flex flex-col flex-1 p-2 pl-5 space-y-2 text-left">
          <div class="flex flex-col py-3 space-y-3 text-lg">
            <h1 class="font-extrabold">Cara Membuat Group</h1>
            <p>
              Dengan menggunakan group anda dapat menggunakan check in di
              berbagai macam usaha anda sekaligus
            </p>

            <h2 class="pt-5 font-bold">
              1. Pilih menu Account
            </h2>
            <img
              src="/img/guides/how-to-create-group/1.png"
              class="shadow"
              alt=""
            />

            <h2 class="pt-5 font-bold">
              2. Klik data Group
            </h2>
            <img
              src="/img/guides/how-to-create-group/2.png"
              class="shadow"
              alt=""
            />

            <h2 class="pt-5 font-bold">
              3. Klik "create a new one" untuk membuat project baru
            </h2>
            <img
              src="/img/guides/how-to-create-group/3.png"
              class="shadow"
              alt=""
            />

            <h2 class="pt-5 font-bold">
              4. Isi dengan nama group yang ingin anda gunakan
            </h2>
            <img
              src="/img/guides/how-to-create-group/4.png"
              class="shadow"
              alt=""
            />

            <h2 class="pt-5 font-bold">
              5. Klik "save" untuk menyimpan data group anda
            </h2>
            <img
              src="/img/guides/how-to-create-group/5.png"
              class="shadow"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
